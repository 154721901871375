@import "../../variables.scss";

.Nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0px;

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--link {
      margin-right: 1rem;
      font-size: 25px;
      cursor: pointer;
      background-image: linear-gradient(270deg, #00dbd8, #b133ff);
      background-size: 0% 3px;
      background-repeat: no-repeat;
      background-position: left bottom;
      transition: all 300ms ease;
      line-height: 40px;
      color: $primaryColor;
      text-decoration: none;

      &:hover {
        background-size: 100% 3px;
        color: white;
      }
    }

    &--download {
      text-decoration: none;
      color: white;
      background: linear-gradient(270deg, #00dbd8 0%, #b133ff 100%);
      padding: 10px 20px;
      border-radius: 20px;
      border: none;

      .fa-download {
        margin-left: 0.5rem;
      }
    }
  }
}

// .App {
//   width: 90%;
//   margin: 0 auto;
//   position: relative;

//   .Home__background {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     z-index: -1;
//     transform: rotate(180deg);
//   }
// }

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@import "../../variables.scss";

.Footer {
  &__title {
    font-size: 100px;
    display: inline-block;
    white-space: pre-wrap;
    background-image: linear-gradient(90deg, #00dbd8, #b133ff);
    background-clip: text;
    color: transparent;
    background-size: 700%;
    animation: bg-animation 4s infinite alternate;
    font-family: "Monoton", cursive;

    @media (min-width: 320px) {
      font-size: 40px;
    }
    @media (min-width: 425px) {
      font-size: 45px;
    }
    @media (min-width: 768px) {
      font-size: 70px;
    }
    @media (min-width: 1024px) {
      font-size: 100px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--links {
      display: flex;
      margin: 3rem;
      width: 50%;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      @media (max-width: 1225px) {
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      a {
        color: $secondaryColor;
        text-decoration: none;
        @media (max-width: 1225px) {
          margin-bottom: 2rem;
        }
      }
      &--contact {
        color: white;
        border: none;
        background-image: linear-gradient(270deg, #00dbd8, #b133ff);
        padding: 10px 20px;
        border-radius: 20px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 25px;
      }
    }
  }

  &__divider {
    border: 1px solid $primaryColor;
  }
  &__copy {
    text-align: center;
    margin: 3rem;
    color: $secondaryColor;
  }
}

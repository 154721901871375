@import "../../variables.scss";

.container {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}
.item {
  width: 90%;
  overflow: visible;
  stroke: #00dbd8;
  stroke-width: 5;
  stroke-linejoin: round;
  stroke-linecap: round;
}

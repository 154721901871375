@import "../../variables.scss";

.Modal {
  width: clamp(30%, 800px, 70%);
  height: auto;
  margin: auto;
  border-radius: 12px;
  background: transparent;
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  color: $primaryColor;
  font-weight: 700;
  overflow: hidden;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;
  }

  &__about {
    padding: 1.5rem;
    background-color: #f0eff1;
    color: $bodyColor;

    @media (max-width: 768px) {
      padding-top: 0px;
    }

    h2 {
      font-size: 50px;
      box-sizing: border-box;
      opacity: 0.3;
      font-family: "Audiowide", cursive;

      @media (max-width: 1145px) {
        font-size: 40px;
      }

      @media (max-width: 950px) {
        font-size: 30px;
      }
    }

    &--icons {
      font-size: 40px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around;

      &--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 110px;

        &--icon {
          margin-top: 2rem;
        }

        &--desc {
          font-size: 12px;
          margin: 0 auto;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }

  &__contact {
    padding: 1.5rem;
    background-color: $bodyColor;

    h2 {
      font-size: 50px;
      opacity: 0.3;
      font-family: "Audiowide", cursive;

      @media (max-width: 1145px) {
        font-size: 40px;
      }

      @media (max-width: 950px) {
        font-size: 30px;
      }
    }

    input {
      box-sizing: border-box;
      width: 100%;
      background-color: $bodyColor;
      padding: 0.5rem;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid $primaryColor;
      outline: none;
      margin-bottom: 1rem;
      color: $secondaryColor;

      &::placeholder {
        color: $secondaryColor;
        opacity: 0.5;
        font-family: "Audiowide", cursive;
      }
    }

    textarea {
      box-sizing: border-box;
      font-family: inherit;
      outline: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid $primaryColor;
      background-color: $bodyColor;
      width: 100%;
      padding: 0.5rem;
      color: $secondaryColor;
      margin-bottom: 1rem;
      resize: none;

      &::placeholder {
        color: $secondaryColor;
        opacity: 0.5;
      }
    }

    button {
      &:disabled {
        text-decoration: none;
        color: white;
        background: gray;
        padding: 10px 20px;
        border-radius: 20px;
        border: none;
        cursor: default;
        font-family: "Audiowide", cursive;
        margin-top: 1rem;
      }

      text-decoration: none;
      color: white;
      background: linear-gradient(270deg, #00dbd8 0%, #b133ff 100%);
      padding: 10px 20px;
      border-radius: 20px;
      border: none;
      cursor: pointer;
      font-family: "Audiowide",
      cursive;
      margin-top: 1rem;
    }
  }

  &__closeContainer {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;

    &--close {
      color: $primaryColor;
      font-size: 45px;
      transition: all 0.24s ease-in-out;
      cursor: pointer;
    }

    &--close:hover {
      color: $secondaryColor;
      transform: rotate(180deg);
    }
  }
}
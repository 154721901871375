@import "../../variables.scss";

.Home {
  position: relative;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .marquee-container {
    padding-top: 40px;
    padding-bottom: 30px;
    overflow-y: hidden;
    @media (max-height: 400px) {
      font-size: 75px;
    }
  }

  &__marquee {
    color: $primaryColor;
    font-size: 100px;
    margin-right: 1rem;
    word-spacing: 2rem;
    font-family: "Monoton", cursive;
  }

  &__contact {
    color: white;
    border: none;
    background-image: linear-gradient(270deg, #00dbd8, #b133ff);
    margin-top: 2rem;
    padding: 10px 20px;
    border-radius: 20px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 25px;
  }

  &__scroll {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

.row-letter {
  font-size: 100px;
  display: inline-block;
  white-space: pre-wrap;
  background-image: linear-gradient(90deg, #00dbd8, #b133ff);
  background-clip: text;
  color: transparent;
  background-size: 700%;
  animation: bg-animation 4s infinite alternate;

  @media (min-width: 320px) {
    font-size: 40px;
  }
  @media (min-width: 425px) {
    font-size: 45px;
  }
  @media (min-width: 768px) {
    font-size: 70px;
  }
  @media (min-width: 1024px) {
    font-size: 100px;
  }
}
.banner-row {
  overflow: hidden;
  display: flex;
  font-family: "Monoton", cursive;
}
.row-message {
  font-size: 2rem;
  color: $secondaryColor;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.ProgressBar {
  background-image: linear-gradient(270deg, #00dbd8, #b133ff);
  height: 3px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 2px;
  transition: width 0.1s ease;
}

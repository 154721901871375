body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Audiowide", cursive;
  background-color: #000;
  color: #fff;
  // background-image: linear-gradient(180deg, #0e1212, #682ae9, #000, #000);
  // overflow-x: hidden;
}
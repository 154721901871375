@import "../../variables.scss";

.Canvas__container {
  text-align: center;
  width: 668px;
  height: 668px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }

  &--bar {
    position: absolute;
    top: 0;
    height: 10px;
    width: 100%;
    background: black;
  }

  #canvas1 {
    width: 100%;
    height: 100%;
  }
}
@import "../../variables.scss";

.mouse {
  width: 2em;
  height: 3rem;
  border: 2px solid #682ae9;
  border-radius: 2rem;
  display: flex;
}
.mouse span {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #3bdbd8;
  display: block;
  border-radius: 50%;
  margin: auto;
  animation: move-wheel 1s linear infinite;
}
.arrow span {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border: 2px solid transparent;
  border-right-color: $secondaryColor;
  border-bottom-color: $secondaryColor;
  transform: rotate(45deg);
  animation: move-arrow 0.7s alternate infinite;
}

@keyframes move-wheel {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(1rem);
  }
}

@keyframes move-arrow {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

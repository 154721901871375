@import "../../variables.scss";

.Work {
  @keyframes bg-animation {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }

  &__title {
    font-size: 100px;
    background-image: linear-gradient(90deg, #00dbd8, #b133ff);
    background-clip: text;
    color: transparent;
    background-size: 700%;
    animation: bg-animation 4s infinite alternate;
    font-family: "Monoton", cursive;

    @media (min-width: 320px) {
      font-size: 40px;
    }
    @media (min-width: 425px) {
      font-size: 45px;
    }
    @media (min-width: 768px) {
      font-size: 70px;
    }
    @media (min-width: 1024px) {
      font-size: 100px;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;

    &--card {
      width: 70%;
      height: 70%;
      margin-bottom: 3rem;
      border-radius: 15px;
      box-shadow: 1px 10px 10px 5px $secondaryColor;
      background-color: $bodyColor;
      transition: box-shadow 0.3s ease;
      position: relative;
      display: flex;
      align-items: flex-end;

      @media (max-width: 1024px) {
        width: 100%;
        height: 100%;
      }

      &:hover {
        box-shadow: 1px 10px 10px 15px $secondaryColor;
      }

      &:nth-child(even) {
        margin-left: auto;
      }

      &--img {
        border-radius: 15px;
        width: 100%;
        height: 100%;
      }

      &--desc {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(180deg, transparent, #682ae9);
        border-radius: 0px 0px 15px 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media (max-width: 768px) {
          height: 100%;
        }

        &--title {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          color: $secondaryColor;
          text-shadow: 0.1em 0.1em 0 $primaryColor, 0.15em 0.15em 0 $bodyColor;

          @media (max-width: 550px) {
            font-size: 20px;
          }
        }

        &--desc {
          display: flex;
          align-items: center;
          margin-left: 1.5rem;
          margin-bottom: 1.15rem;
          width: 75%;
          text-shadow: 2px 2px 0 $bodyColor;
        }
      }

      &--github {
        font-size: 30px;
        cursor: pointer;
        color: white;
        position: absolute;
        right: 1.5rem;
        bottom: 1rem;
      }
    }
  }
}
